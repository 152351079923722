import { decibelScript } from "./medallia-dxa-script";
declare global {
  interface Window {
    decibelInsight?: void;
  }
}

export const inject = (): void => {
  const isNotLoaded = typeof window.decibelInsight === "undefined";
  if (isNotLoaded) {
    decibelScript();
  }
};
